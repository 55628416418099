<template>
  <v-app style="background-color: #f2f2f2">
    <v-layout row wrap align-center justify-center>
      <v-flex lg4 md5 sm7 xs7 class="text-center">
        <img src="@/assets/logo_onebox-horizontal-png.png" width="45%" height="45%" alt="OneBox" />
        <br />
        <br />
        <v-progress-linear
          v-if="fail_ === false"
          background-color="light-blue lighten-4"
          color="success"
          indeterminate
          rounded
        ></v-progress-linear>
        <br />
        <p v-if="fail_ === false" class="text-center">รอสักครู่</p>
        <p v-else class="text-center"><v-icon color="error">error</v-icon>&nbsp;{{errormessage}}</p>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data: function() {
    return {
      fail_: false,
      errormessage: "",
    };
  },
  created() {},
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataLoginDefault",
    ]),
  },
  methods: {
    reset_login() {
      this.$store
        .dispatch("authorize_expired")
        .then(this.login())
        .catch((error) => {
          console.log(error);
        });
    },
    async login() {
      //เช็คว่ามี accesstoken มั้ย
      if (!this.$route.query.accesstoken) {
        this.fail_ = true;
        this.errormessage = "Accesss Denied !!"        
      } else {
        let _accesstoken = this.$route.query.accesstoken;
        let _taxid = this.$route.query.taxid;
        let folder_id = this.$route.query.folder_id;
        let branch_no = this.$route.query.branch_no || "00000";

        let payload = {
          accesstoken: _accesstoken,
        };

        //localStorage.clear();
        let encodetoken = window.btoa(_accesstoken)
        sessionStorage.setItem("token", encodetoken);
        sessionStorage.setItem("checklogin", true);
        // localStorage.setItem("token", encodetoken);
        // localStorage.setItem("token", _accesstoken);
        VueCookies.set('token', encodetoken);
        VueCookies.set('token_auth', true);

        // 1 ยิง API getprofile
        let check_auth = await this.$store.dispatch("authorize_checking", payload);
        if (check_auth.status === "Access Granted") {
          // เช็คว่ามี taxid / folderid มั้ย
          if (!_taxid) {
            this.fail_ = true;
            this.errormessage = "ไม่พบ tax id !!"
          } else if (!folder_id) {
            this.fail_ = true;
            this.errormessage = "ไม่พบ folder id !!"
          } else {
            let objIndex = this.dataBusinessProfile.findIndex((obj) => obj.branch_no === branch_no & obj.id_card_num === _taxid);
            if (objIndex === -1) {
              this.fail_ = true;
              this.errormessage = "ไม่พบบริษัท !!"
            } else {
              let databiz_id = this.dataBusinessProfile[objIndex].id;
              let payload_bu = {
                taxid: _taxid,
                accesstoken: _accesstoken,
                biz_id: databiz_id,
                branch_no: this.dataBusinessProfile[objIndex].branch_no || '00000'
              };
              // 2 ยิง API Login Business
              this.$store.dispatch("switchaccount_business", payload_bu).then(async (msg) => {
                if (msg.status === "Business Access Granted") {
                  this.$router.push({ name: "directory", params: { id: folder_id } });                  
                }
              });
            }
          }
          let user_id_key = (Math.random() + 1).toString(36).substring(7);
          VueCookies.set("user_key", user_id_key);
          VueCookies.set("accountid", CryptoJS.AES.encrypt(window.btoa(this.dataAccountActive.accountid), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
          localStorage.setItem("thai_email", CryptoJS.AES.encrypt(window.btoa(this.dataAccountActive["thai_email"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
          localStorage.setItem("username", CryptoJS.AES.encrypt(window.btoa(this.dataAccountActive["username"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
          localStorage.setItem("user_id", CryptoJS.AES.encrypt(window.btoa(this.dataAccountActive["user_id"]), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, }).toString());
        } else {
          this.fail_ = true;
          this.errormessage = "ไม่สามารถ login ได้ !!"
        }
      }
    }
  },
  mounted() {
    console.log(",kkkkkkkkk", this.$route.query);
    this.reset_login();
  }
}
</script>
